/* global BigInt */
import React, { useEffect } from "react";
import extjs from "../../ic/extjs.js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import SaleListing from "../SaleListing";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Timestamp from "react-timestamp";
import Pagination from "@material-ui/lab/Pagination";
import { StoicIdentity } from "ic-stoic-identity";
import Sidebar from "../Sidebar";
import { useParams } from "react-router";
import Navbar from "../../containers/Navbar.js";
import { Principal } from "@dfinity/principal";  
const api = extjs.connect("https://boundary.ic0.app/");
const perPage = 60;
function useInterval(callback, delay) {
  const savedCallback = React.useRef();
  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
const _showListingPrice = (n) => {
  n = Number(n) / 100000000;
  return n.toFixed(8).replace(/0{1,6}$/, "");
};
// const _getRandomBytes = () => {
//   var bs = [];
//   for (var i = 0; i < 32; i++) {
//     bs.push(Math.floor(Math.random() * 256));
//   }
//   return bs;
// };
export default function ICPugs(props) {
  const [page, setPage] = React.useState(1);
  // const [price, setPrice] = React.useState(55000000n);
  // const [remaining, setRemaining] = React.useState(false);
  const [remaining, setRemaining] = React.useState(true);
  const [startTime, setStartTime] = React.useState(1649066400000);
  var totalToSell = 9796; // change this
  var saleOver = false;  
  const params = useParams();
  
  const _updates = async () => { // TODO**********************************
    // var stats = await api.canister("p5jg7-6aaaa-aaaah-qcolq-cai", "sale").salesStats((props.account ? props.account.address : "")); // change this to get valid price
    // setPrice(stats[1]); // price at given round (salesStats)
    // setStartTime(Number(stats[0]/1000000n));
    // setRemaining(Number(stats[2]));
    var stats = await api.canister("awbbf-xqaaa-aaaai-qieaa-cai", "sale").mintStats();
    setStartTime(Number(stats[0]/1000000n));
    setRemaining(Number(stats[1]));
  };
  const theme = useTheme();
  const classes = useStyles();
  const styles = {
    main: {
      maxWidth: 1200,
      margin: "0 auto",
      textAlign: "center",
      minHeight:"calc(100vh - 221px)"
    },
  };
  // const buyFromSale = async (qty, price) => {
    const buyFromSale = async (qty) => {
    // if (props.balance < (price + 10000n)){
    //   return props.alert(
    //     "There was an error",
    //     "Your balance is insufficient to complete this transaction"
    //   );
    // }
    // var v = await props.confirm("Please confirm", "Are you sure you want to continue with this purchase of " + qty + " NFT"+(qty === 1 ? "" : "s")+" for the total price of " + _showListingPrice(price) + " ICP. All transactions are final on confirmation and can't be reversed.")
    var v = await props.confirm("Please confirm", "Continue with this purchase of " + qty + " ICPug"+(qty === 1 ? "" : "s")+"? All transactions are final on confirmation and can't be reversed.")
    if (!v) return;
    try {
      if (qty === 1) {
        props.loader(true, "Randomly selecting NFT...");
      } else {
        props.loader(true, "Randomly selecting NFTs..");
      }
      const api = extjs.connect("https://boundary.ic0.app/", props.identity);
      // var discount = await api.canister("awbbf-xqaaa-aaaai-qieaa-cai", "sale").getDisCountByUser(Principal.fromText(props.account.principal));
      // var approve_amt = qty * ((300000000 * discount) / 100);
      let approve_amt = await api.canister("o5d6i-5aaaa-aaaah-qbz2q-cai", "ccc_wicp").balanceOf(Principal.fromText(props.account.principal));
      let wicp_approve = await api.canister("o5d6i-5aaaa-aaaah-qbz2q-cai", "ccc_wicp").approve(Principal.fromText("awbbf-xqaaa-aaaai-qieaa-cai"), approve_amt);
      if (wicp_approve.hasOwnProperty("err")) {
          throw wicp_approve.err;
        }
      var r = await api.canister("awbbf-xqaaa-aaaai-qieaa-cai", "sale").mint(qty); // change to icpugs nft canister
      // if (r.hasOwnProperty("err")) {
      //   props.loader(false);
      //   props.alert(
      //     "There was an error",
      //     r.err
      //   );
      //   throw r.err;
      // }
      var nftIDArr = r.ok;
      var tokensMinted = "";
      function concatId(CanvasIdentity) {
        var nftTxt = " #" + CanvasIdentity.index.toString();
        tokensMinted += nftTxt;
      }
      nftIDArr.forEach(concatId)
      var successAlertTxt = "Successfully purchased ICPugs" + tokensMinted + ". Your NFTs will be sent to you shortly";
      // var r = await api
      //   .canister("p5jg7-6aaaa-aaaah-qcolq-cai", "sale")
      //   .reserve(
      //     price,
      //     qty,
      //     props.account.address,
      //     _getRandomBytes()
      //   );
      // if (r.hasOwnProperty("err")) {
      //   throw r.err;
      // }
      // var paytoaddress = r.ok[0];
      // var pricetopay = r.ok[1];
      // props.loader(true, "Transferring ICP...");
      // await api
      //   .token()
      //   .transfer(
      //     props.identity.getPrincipal(),
      //     props.currentAccount,
      //     paytoaddress,
      //     pricetopay,
      //     10000
      //   );
      // var r3;
      // while (true) {
      //   try {
      //     props.loader(true, "Completing purchase...");
      //     r3 = await api.canister("p5jg7-6aaaa-aaaah-qcolq-cai", "sale").retreive(paytoaddress);
      //     if (r3.hasOwnProperty("ok")) break;
      //   } catch (e) {}
      // }
      // props.loader(false);
      // props.alert(
      //   "Transaction complete",
      //   "Your purchase was made successfully - your NFT will be sent to your address shortly"
      // );
      props.loader(false);
      props.alert(
        "Transaction complete",
        successAlertTxt
      );
      _updates();
    } catch (e) {
      props.loader(false);
      props.alert(
        "There was an error",
        e.Other ?? (typeof e == "string" ? e : "You may need to enable cookies or try a different browser")
      );
    }
  };
  useInterval(_updates, 5 * 1000);
  React.useEffect(() => {
    _updates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  var buyOptions = () => {
    return [
          [1],
          [5],
          [10],
          [20],
        ];
    // if (price == 55000000n) {
    //   return [
    //     [1, 55000000n],
    //     [5, 275000000n],
    //     [10, 525000000n],
    //     [20, 1000000000n],
    //   ];
    // } else {
    //   return [
    //     [1, 40000000n],
    //     [5, 200000000n],
    //     [10, 375000000n],
    //     [20, 750000000n],
    //   ];
    // };
  };
  return (
    <>
      <div style={styles.main}>
        <div className={classes.banner}>
        <div style={{width: "100%", height: 580, borderRadius:10,backgroundPosition: "center", backgroundSize: "cover",backgroundImage:"url('/collections/icpugs/30icp.png')"}}></div>
        <h1 style={{color:"#011936"}}>Welcome to the official ICPugs sale</h1>
        </div>
        <Grid  justifyContent="center" direction="row" alignItems="center" container spacing={2} style={{}}>
          <Grid className={classes.stat} item md={4} xs={6}>
            <strong style={{color:"#011936"}}>OG PRICE</strong><br />
            <span style={{fontWeight:"bold",color:"#FFB300",fontSize:"2em"}}>{_showListingPrice(240000000n)} WICP</span>
          </Grid>
          <Grid className={classes.stat} item md={4} xs={6}>
            <strong style={{color:"#011936"}}>WHITELIST PRICE</strong><br />
            <span style={{fontWeight:"bold",color:"#FFB300",fontSize:"2em"}}>{_showListingPrice(270000000n)} WICP</span>
          </Grid>
          <Grid className={classes.stat} item md={4} xs={6}>
            <strong style={{color:"#011936"}}>PUBLIC PRICE</strong><br />
            <span style={{fontWeight:"bold",color:"#FFB300",fontSize:"2em"}}>{_showListingPrice(300000000n)} WICP</span>
          </Grid>
          <Grid className={classes.stat} item md={4} xs={6}>
            <strong style={{color:"#011936"}}>AVAILABLE</strong><br />
            <span style={{fontWeight:"bold",color:"#FFB300",fontSize:"2em"}}>{remaining !== false ? remaining : "Loading..."}</span>
          </Grid>
          <Grid className={classes.stat} item md={4} xs={6}>
            <strong style={{color:"#011936"}}>SOLD</strong><br />
            <span style={{fontWeight:"bold",color:"#FFB300",fontSize:"2em"}}>{remaining !== false ? totalToSell-remaining : "Loading..."}</span>
          </Grid>
        </Grid>
        <br /><br />
        {remaining === false ? 
          <>
            <p><strong><span style={{fontSize:"20px",color:"#ED254E"}}>Loading...</span></strong></p>
          </>
        : 
          <>{(!saleOver && remaining > 0) ?
            <>
              {Date.now() < startTime  ? 
                <>
                  <p><strong><span style={{fontSize:"20px",color:"#ED254E"}}>The sale starts <Timestamp relative autoUpdate date={startTime/1000} />!</span></strong></p>
                </> : ""
              }
              {Date.now() >= startTime ? 
                <>
                  <Grid justifyContent="center" direction="row" alignItems="center" container spacing={2} style={{}}>
                    {buyOptions().map(o => {
                      return (<Grid className={classes.stat} item sm={2}>
                        <Button
                          variant={"contained"}
                          color={"primary"}
                          // onClick={() => buyFromSale(o[0], o[1])}
                          onClick={() => buyFromSale(o[0])}
                          style={{ fontWeight: "bold", margin: "0 auto", color: "white" }}
                        >
                          {/* Buy {o[0]} NFT{o[0] === 1 ? "" : "s"}<br />for {_showListingPrice(o[1])} ICP */}
                          Mint  <br />{o[0]}  ICPug{o[0] === 1 ? "" : "s"}
                        </Button>
                      </Grid>);
                    })}
                  </Grid>
                  
                </> :""
              }
            </>
          : 
            // <p><strong><span style={{fontSize:"20px",color:"red"}}>Sorry, the sale is now over (pending any failed transactions, which will be recycled soon)! You can grab your NFT from the marketplace soon!</span></strong></p>
            <p><strong><span style={{fontSize:"20px",color:"#ED254E"}}>Oh no, the sale is over! ICPugs will soon be available on CCC marketplace for purchase!</span></strong></p>
          }</>
        }
      </div>
      
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  walletBtn: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  stat: {
    span : {
      fontSize: "2em"
    }
  },
  content: {
    flexGrow: 1,
    marginTop: 73,
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 300,
    },
  },
}));
