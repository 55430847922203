import { red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import "@fontsource/bungee";
import "@fontsource/bubblegum-sans";
import "@fontsource/bree-serif";
import "@fontsource/odor-mean-chey";
import "@fontsource/suez-one";
import "@fontsource/holtwood-one-sc";

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: "Suez One, Holtwood One SC, Odor Mean Chey, Bree Serif, Bubblegum Sans, Bungee, Poppins",
  },
  palette: {
    primary: {
      main: "#03a8f4", // #E2F0F9 for blue announcement, #483E5E for text
    },
    secondary: {
      main: "#00bbd4",//"#00b894",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#F4FFFD" //"#393E41" //"#A8CCC9" // "#F1EFED", //"#777d8f",
    },
  },
  overrides: {
    MuiIconButton: {
      label: {
        color: "#00bbd4",//"#00b894",
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: "#00bbd4",//"#B3D6C6",
        color: "white",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "#00bbd4", //"#00b894",
      },
    },
  },
});

export default theme;
