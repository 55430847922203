import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Wallet from "../components/Wallet";
import MenuIcon from "@material-ui/icons/Menu";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { IconButton, makeStyles } from "@material-ui/core";

export default function Navbar(props) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState("");
  const [walletOpen, setWalletOpen] = React.useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setWalletOpen(false)
    const temp = history.location.pathname.split("/")[1];
    setRoute(temp);
  };
  const goTo = page => {
    history.push(page)
    handleClick();
  };
  const handleDrawerToggle = () => {
    setWalletOpen(!walletOpen);
  };
  React.useEffect(() => {
    const temp = history.location.pathname.split("/")[1];
    setRoute(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" style={{zIndex: 1400, background: "#F4FFFD" }}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            {/* <a onClick={() => goTo("/")}> */}
            <a href="https://icpugs.com/">
              <img
              alt="ICPugs Logo"
              // src="/icpugslogoclean.png"
              src="/icpugslogofull.png"
              style={{ height: 64, cursor: "pointer" }}
            /></a>
          </Typography>
          <div className={classes.grow} />
          <Button
            onClick={handleDrawerToggle}
            color="inherit"
            className={[classes.button].join(' ')}
          >
          <AccountBalanceWalletIcon fontSize="large" />
          </Button>

          <IconButton className={classes.hidden}>
            <MenuIcon onClick={() => {setOpen(true); setWalletOpen(false)}} />
          </IconButton>
          {open && (
            <div className={classes.smNav} onClick={() => setOpen(false)}>
              <Button
                startIcon={
                  <AccountBalanceWalletIcon />
                }
                onClick={handleDrawerToggle}
                className={classes.button1}
                style={{
                  color: "#011936",
                  borderBottom:"3px solid transparent",
                }}
                color="inherit"
              >
              Wallet
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {props.children}
    </div>
    
    <Wallet
      processPayments={props.processPayments}
      view={props.view}
      setBalance={props.setBalance}
      identity={props.identity}
      account={props.account}
      loader={props.loader}
      logout={props.logout}
      login={props.login}
      collection={props.collection}
      collections={props.collections}
      currentAccount={props.currentAccount}
      changeAccount={props.changeAccount}
      accounts={props.accounts}
      close={() => setWalletOpen(false)}
      open={walletOpen}
    />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  hidden: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  smNav: {
    position: "absolute",
    top: 20,
    width: "250px",
    display: "flex",
    right: 0,
    backgroundColor: "white",
    height: "100vh",
    justifyContent: "flex-start",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  toolbarButtons: {
    marginLeft: "auto",
  },
  content: {
    flexGrow: 1,
  },
  button: {
    marginLeft: 30,
    fontSize: "1.2em",
    fontWeight: "bold",
    borderBottom: "3px solid transparent",
    borderRadius: 0,
    height: 73,
    "&:hover, &.selected": {
      color: "#FFB300 !important",
      backgroundColor: "#fff",
      borderBottom: "3px solid #FFB300 !important",
    },

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  button1: {
    fontSize: "1.2em",
    fontWeight: "bold",
    borderBottom: "3px solid transparent",
    borderRadius: 0,
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "30px",
    paddingTop: "40px",
    height: 73,
    "&:hover": {
      color: "#FFB300 !important",
      backgroundColor: "#fff",
      borderBottom: "3px solid #FFB300 !important",
    },
  },
}));
