import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";

var collections = [
  {
    canister: "awbbf-xqaaa-aaaai-qieaa-cai",
    priority : 100,
    name: "ICPugs",
    brief : "10,000 8-bit style pug NFTs lounging on the InternetComputer",
    description : "A Collection of 10,000 8-bit style pug NFTs lounging on the Internet Computer",
    blurb: (<><p>ICPugs is a collection of 10,000 chonky 8-bit style pugs that were randomly generated from well over 2.185 Trillion unique combinations. ICPugs are goofy, silly and love eating snackos. Ownership of ICPugs, grants their owners special economic privileges such as operating virtual storefronts, and access to curated experiences within PugChat. Which is a 2D metaverse hangout the ICPugs team is developing.</p></>),
    keywords : "ICPugs Internet Computer NFTs",
    web : "https://icpugs.com",
    //telegram : "https://t.me/+odsWbiTXIfQyYmE0",
    discord : "https://t.co/7kwC8TcS72",
    twitter : "https://twitter.com/ic_pugs",
    medium : "",
    avatar : "/collections/icpugs/avatar.jpg",
    collection : "/collections/icpugs/banner.jpg",
    banner : "/collections/icpugs/banner.jpg",
    route: "icpugs",
    commission: 0.04,
    comaddress: "",
    unit : "NFT",
    nftv : false,
    mature: false,
    market : false,
  },
//   {
//     canister: "njgly-uaaaa-aaaah-qb6pa-cai",
//     priority : 10,
//     name: "ICPuppies",
//     unit : "ICPuppy",
//     brief : "10,000 unique randomly generated 8-bit puppies",
//     description : "ICPuppies is a randomly generated 8-bit puppy NFT collection. Half of the initial sale profits will be directed towards charities as well as a portion of all resale volume.",
//     route: "icpuppies",
//     keywords : "puppies icpuppies nft charity 8-bit pixel dog doge puppy",
//     banner : "/collections/icpuppy/banner.jpg",
//     avatar : "/collections/icpuppy/avatar.jpg",
//     //collection : "/collections/iasc/collection.jpg",
//     discord : "https://discord.gg/A3rmDSjBaJ",
//     twitter : "https://twitter.com/ICPuppies",
//     medium : "https://medium.com/@ICPuppies",
//     web : "https://icpuppies.io",
//     nftv : true,
//     mature: false,
//     market : true,
//     commission: 0.035,
//     comaddress:
//       "9f76290b181807c5fa3c7cfcfca2525d578a3770f40ae8b14a03a4a3530368e2",
//     blurb: (
//       <>ICPuppies was created with the idea of being a fun, easy-going collection that's very accessible to all newcomers to the Internet Computer NFT world. We're inspired by the retro 8-bit video game aesthetic as we feel it's appealing to people everywhere.<br /><br />Half of the initial launch profits are directed towards charities and on top of that, a portion of our resale volume goes to our partners at Infinite Charity Project, forever! 
// </>
//     ),
//   },
];
export default collections;
